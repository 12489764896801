import React, { useEffect } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography,
} from '@mui/material'

// third party
import * as Yup from 'yup'
import { Formik } from 'formik'

// project imports
// import useAuth from 'hooks/useAuth';
import useScriptRef from 'hooks/useScriptRef'
import AnimateButton from 'ui-component/extended/AnimateButton'
import { strengthColor, strengthIndicator } from 'utils/password-strength'

import useAuth from 'hooks/useAuth'
// assets
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { StringColorProps } from 'types'
import { openSnackbar } from 'store/slices/snackbar'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// ========================|| FIREBASE - RESET PASSWORD ||======================== //

const AuthResetPassword = ({ ...others }) => {
    const theme = useTheme()
    const scriptedRef = useScriptRef()
    const [showPassword, setShowPassword] = React.useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false)
    const [strength, setStrength] = React.useState(0)
    const [level, setLevel] = React.useState<StringColorProps>()
    const passwordRegx =
        /^(?=.*\d)(?=.*[!@#$%^&*()-])(?=.*[a-z])(?=.*[A-Z]).{6,}$/
    const navigate = useNavigate()

    const dispatch = useDispatch()
    // const { firebaseEmailPasswordSignIn } = useAuth();

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword)
    }

    const handleMouseDownPassword = (event: React.SyntheticEvent) => {
        event.preventDefault()
    }

    const changePassword = (value: string) => {
        const temp = strengthIndicator(value)
        setStrength(temp)
        setLevel(strengthColor(temp))
    }

    const { resetPassword } = useAuth()
    useEffect(() => {
        changePassword('')
    }, [])

    return (
        <Formik
            initialValues={{
                email: 'info@codedthemes.com',
                password: '',
                confirmPassword: '',
                submit: null,
            }}
            validationSchema={Yup.object().shape({
                password: Yup.string()
                    .max(255)
                    .required('Password is required'),
                confirmPassword: Yup.string().when('password', {
                    is: (val: string) => !!(val && val.length > 0),
                    then: Yup.string().oneOf(
                        [Yup.ref('password')],
                        'Both Password must be match!'
                    ),
                }),
            })}
            onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
            ) => {
                try {
                    const response = await resetPassword({
                        ...others.state,
                        password: values.password,
                    })
                    if (response.status === 200) {
                        navigate('/login')
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: response?.data?.message,
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right',
                                },
                                variant: 'alert',
                                alert: {
                                    color: 'success',
                                },
                                close: false,
                            })
                        )
                    } else {
                        dispatch(
                            openSnackbar({
                                open: true,
                                message:
                                    response?.ex ||
                                    'Invalid verification code.',
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right',
                                },
                                variant: 'alert',
                                severity: 'error',
                                alert: {
                                    color: 'error',
                                },
                                close: false,
                            })
                        )
                    }

                    if (scriptedRef.current) {
                        setStatus({ success: true })
                        setSubmitting(false)
                    }
                } catch (err: any) {
                    if (scriptedRef.current) {
                        setStatus({ success: false })
                        setErrors({ submit: err.message })
                        setSubmitting(false)
                    }
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
            }) => (
                <form noValidate onSubmit={handleSubmit} {...others}>
                    <InputLabel>Password</InputLabel>
                    <OutlinedInput
                        type={showPassword ? 'text' : 'password'}
                        value={values.password}
                        name="password"
                        onBlur={handleBlur}
                        fullWidth
                        error={Boolean(touched.password && errors.password)}
                        sx={{ ...theme.typography.customInput }}
                        onChange={(e) => {
                            handleChange(e)
                            changePassword(e.target.value)
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    size="large"
                                >
                                    {showPassword ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        }
                        inputProps={{}}
                    />

                    {touched.password && errors.password && (
                        <FormControl fullWidth>
                            <FormHelperText
                                error
                                id="standard-weight-helper-text-reset"
                            >
                                {errors.password}
                            </FormHelperText>
                        </FormControl>
                    )}
                    {strength !== 0 && (
                        <FormControl fullWidth>
                            <Box sx={{ mb: 2 }}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item>
                                        <Box
                                            style={{
                                                backgroundColor: level?.color,
                                            }}
                                            sx={{
                                                width: 85,
                                                height: 8,
                                                borderRadius: '7px',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            variant="subtitle1"
                                            fontSize="0.75rem"
                                        >
                                            {level?.label}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </FormControl>
                    )}

                    <InputLabel>Confirm Password</InputLabel>
                    <OutlinedInput
                        type={showConfirmPassword ? 'text' : 'password'}
                        value={values.confirmPassword}
                        name="confirmPassword"
                        label="Confirm Password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        fullWidth
                        error={Boolean(
                            touched.confirmPassword && errors.confirmPassword
                        )}
                        sx={{ ...theme.typography.customInput }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    size="large"
                                >
                                    {showConfirmPassword ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        }
                    />

                    {touched.confirmPassword && errors.confirmPassword && (
                        <FormControl fullWidth>
                            <FormHelperText
                                error
                                id="standard-weight-helper-text-confirm-password"
                            >
                                {' '}
                                {errors.confirmPassword}{' '}
                            </FormHelperText>
                        </FormControl>
                    )}

                    {errors.submit && (
                        <Box
                            sx={{
                                mt: 3,
                            }}
                        >
                            <FormHelperText error>
                                {errors.submit}
                            </FormHelperText>
                        </Box>
                    )}
                    <Box
                        sx={{
                            mt: 1,
                        }}
                    >
                        <AnimateButton>
                            <Button
                                disableElevation
                                disabled={
                                    values.password.trim() === '' ||
                                    !passwordRegx.test(values.password) ||
                                    values.password !== values.confirmPassword
                                }
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                sx={{ color: '#fff' }}
                            >
                                Reset Password
                            </Button>
                        </AnimateButton>
                    </Box>
                </form>
            )}
        </Formik>
    )
}

export default AuthResetPassword
