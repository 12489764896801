// third-party
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

// project imports
import axios from 'utils/axios'
import { dispatch, RootState } from '../index'
import { openSnackbar } from './snackbar'

// ----------------------------------------------------------------------

export const getAllPreference = createAsyncThunk(
    'preferences/getAllpreferences',
    async (args: undefined, { rejectWithValue }) => {
        try {
            const response = await axios.get('profile/preferences')
            const { data } = response
            return data
        } catch (e: any) {
            return rejectWithValue(e)
        }
    }
)

export const createPreference = createAsyncThunk(
    'preferences/createPreference',
    async (args: undefined, { rejectWithValue, getState }) => {
        axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'

        try {
            const {
                preference: {
                    addPreference: { data: preference },
                },
            }: any = getState()
            const formData = new FormData()
            formData.append('name', preference.name)

            preference.tags.forEach((value: any, index: any) => {
                for (const key in value) {
                    formData.append(`tags[${index}][${key}]`, value[key])
                }
            })

            formData.append('files', preference.imageURL)

            const response = await axios.post(`admin/preference`, formData)
            const { data } = response
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Prefrence created.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success',
                    },
                    close: false,
                })
            )
            dispatch(getAllPreference())
            return data
        } catch (e) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Error.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    severity: 'error',
                    alert: {
                        color: 'error',
                    },
                    close: false,
                })
            )
        }
        return null
    }
)

export const updatePreferences = createAsyncThunk(
    'preferences/updatePreferences',
    async (args: undefined, { rejectWithValue, getState }) => {
        axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'

        try {
            const {
                preference: {
                    addPreference: { data: preference },
                },
            }: any = getState()
            const formData = new FormData()

            if (preference.tags.length !== 0) {
                preference.tags.forEach((value: any, index: any) => {
                    for (const key in value) {
                        formData.append(`tags[${index}][${key}]`, value[key])
                    }
                })
            } else {
                const tags: any = []
                formData.append('tags[]', tags)
            }
            if (typeof preference.imageURL !== 'string') {
                formData.append('files', preference.imageURL)
            }

            const response = await axios.put(
                `admin/preference/${preference.id}`,
                formData
            )
            const { data } = response
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Prefrence updated.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success',
                    },
                    close: false,
                })
            )
            dispatch(getAllPreference())
            return data
        } catch (e) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Error.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    severity: 'error',
                    alert: {
                        color: 'error',
                    },
                    close: false,
                })
            )
        }
        return null
    }
)

export const deletepreferences = createAsyncThunk(
    'preferences/deleteQuestion',
    async ({ quote }: any, { rejectWithValue }) => {
        const uid = quote?.uid
        try {
            const response = await axios.delete(`admin/quote/${uid}`)
            const { data } = response
            dispatch(getAllPreference())
            return data
        } catch (e) {
            return rejectWithValue(e)
        }
    }
)

export const getAllTags = createAsyncThunk(
    'tags/getAllTags',
    async (args: undefined, { rejectWithValue }) => {
        try {
            const response = await axios.get('admin/tags')
            const { data } = response
            return data
        } catch (e: any) {
            return rejectWithValue(e)
        }
    }
)

const apiResponse: any = {
    data: [],
    error: false,
    errorMessage: '',
    isFetching: false,
    isSuccess: false,
    successMessage: '',
}

const initialState: any = {
    preferences: apiResponse,
    addPreference: {
        ...apiResponse,
        data: { name: '', tags: [], imageURL: null },
    },
    tags: [],
}

const PreferenceSlice = createSlice({
    name: 'preference',
    initialState,
    reducers: {
        clearPreference: (state: any, action: PayloadAction<any>) => {
            state.preferences = apiResponse
        },
        setPreferenceData: (state: any, { payload }: PayloadAction<any>) => {
            const { key, value } = payload
            state.addPreference.data[key] = value
        },
        setEditPreferenceData: (
            state: any,
            { payload }: PayloadAction<any>
        ) => {
            state.addPreference.data = payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllPreference.pending, (state: any) => {
                state.preferences.isFetching = true
            })
            .addCase(
                getAllPreference.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.preferences.data = payload
                    state.preferences.isFetching = false
                }
            )
            .addCase(
                getAllPreference.rejected,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.preferences.isError = true
                    state.preferences.isFetching = false
                    state.preferences.errorMessage = payload
                }
            )
            .addCase(
                createPreference.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.addPreference = {
                        ...apiResponse,
                        data: { name: '', tags: [], imageURL: null },
                    }
                }
            )
            .addCase(createPreference.pending, (state: any) => {
                state.addPreference.isFetching = true
            })
            .addCase(
                createPreference.rejected,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.addPreference.isError = true
                    state.addPreference.isFetching = false
                    state.addPreference.errorMessage = payload
                }
            )
            .addCase(getAllTags.pending, (state: any) => {
                state.preferences.isFetching = true
            })
            .addCase(
                getAllTags.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.tags = payload
                    state.preferences.isFetching = false
                }
            )
            .addCase(
                getAllTags.rejected,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.preferences.isError = true
                    state.preferences.isFetching = false
                    state.preferences.errorMessage = payload
                }
            )
            .addCase(updatePreferences.pending, (state: any) => {
                state.addPreference.isFetching = true
            })
            .addCase(
                updatePreferences.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.addPreference = {
                        ...apiResponse,
                        data: { name: '', tags: [], imageURL: null },
                    }
                }
            )
            .addCase(
                updatePreferences.rejected,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.addPreference.isError = true
                    state.addPreference.isFetching = false
                    state.addPreference.errorMessage = payload
                }
            )
    },
})

export const { clearPreference, setPreferenceData, setEditPreferenceData } =
    PreferenceSlice.actions
export const preferencesSelector = (state: RootState) => state.preference
export default PreferenceSlice.reducer
