// third-party
import {
    createAsyncThunk,
    createSlice,
    isAnyOf,
    PayloadAction,
} from '@reduxjs/toolkit'

// project imports
import axios from 'utils/axios'
import { dispatch, RootState } from '../index'
import { openSnackbar } from './snackbar'

// ----------------------------------------------------------------------

export const getAllQuestions = createAsyncThunk(
    'questions/getAllQuestions',
    async (args: undefined, { rejectWithValue }) => {
        try {
            const response = await axios.get('admin/survey')
            const { data } = response
            return data
        } catch (e: any) {
            return rejectWithValue(e)
        }
    }
)

export const createQuestion = createAsyncThunk(
    'questions/createQuestion',
    async ({ question }: any, { rejectWithValue }) => {
        try {
            const response = await axios.post(`admin/survey`, question)
            const { data } = response
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Question created.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success',
                    },
                    close: false,
                })
            )
            dispatch(getAllQuestions())
            return data
        } catch (e) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Multiple answers selection is not correct.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    severity: 'error',
                    alert: {
                        color: 'error',
                    },
                    close: false,
                })
            )
            return rejectWithValue(e)
        }
    }
)

export const updateQuestion = createAsyncThunk(
    'questions/updateQuestion',
    async ({ question }: any, { rejectWithValue }) => {
        const {
            question: newQuestion,
            options,
            uid,
            multipleAnswer,
            maxSelection,
            minSelection,
        } = question
        try {
            const response = await axios.put(`admin/survey`, {
                questionID: uid,
                data: {
                    question: newQuestion,
                    options,
                    multipleAnswer,
                    maxSelection,
                    minSelection,
                },
            })
            const { data } = response
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Question updated.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success',
                    },
                    close: false,
                })
            )
            dispatch(getAllQuestions())
            return data
        } catch (e) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Error.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    severity: 'error',
                    alert: {
                        color: 'error',
                    },
                    close: false,
                })
            )
            return rejectWithValue(e)
        }
    }
)
export const deleteQuestion = createAsyncThunk(
    'questions/deleteQuestion',
    async ({ question }: any, { rejectWithValue }) => {
        const { uid } = question
        try {
            const response = await axios.delete(`admin/survey/${uid}`)
            const { data } = response
            dispatch(getAllQuestions())
            return data
        } catch (e) {
            return rejectWithValue(e)
        }
    }
)

export const updateQuestionSequence = createAsyncThunk(
    'questions/updateQuestionSequence',
    async ({ questions }: any, { rejectWithValue, getState }) => {
        const temp = await questions.map((item: any, index: number) => {
            const options = item?.options.map((oItem: any, oIndex: number) => ({
                ...oItem,
                order: oIndex + 1,
            }))

            return {
                ...item,
                order: index + 1,
                questionID: item.uid,
                options,
            }
        })

        const { questions: previousQuestions }: any = getState()
        const tempQuestions: any = previousQuestions.data
        dispatch(setQuestionsData(temp))

        try {
            const response = await axios.post(`admin/survey/updateAll`, {
                data: temp,
            })

            const { data } = response

            // dispatch(getAllQuestions());
            return data
        } catch (e) {
            dispatch(setQuestionsData(tempQuestions))
            return rejectWithValue(e)
        }
    }
)

export const activateDeactivateQuestion = createAsyncThunk(
    'questions/activateDeactivateQuestion',
    async ({ question }: any, { rejectWithValue }) => {
        const { uid, isActive } = question
        try {
            const response = await axios.put(`admin/survey`, {
                questionID: uid,
                data: {
                    isActive: !isActive,
                },
            } as any)
            const { data } = response
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Question updated.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success',
                    },
                    close: false,
                })
            )
            dispatch(getAllQuestions())
            return data
        } catch (e) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Error.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    severity: 'error',
                    alert: {
                        color: 'error',
                    },
                    close: false,
                })
            )
            return rejectWithValue(e)
        }
    }
)

const apiResponse: any = {
    data: [],
    error: false,
    errorMessage: '',
    isFetching: false,
    isSuccess: false,
    successMessage: '',
}

const questionState: any = {
    question: '',
    options: [],
    isActive: true,
}

const initialState: any = {
    questions: apiResponse,
    addQuestion: {
        ...apiResponse,
        data: questionState,
    },
}

const questionsSlice = createSlice({
    name: 'questions',
    initialState,
    reducers: {
        clearQuestions: (state: any, action: PayloadAction<any>) => {
            state.questions = apiResponse
        },
        setQuestionsData: (state: any, action: PayloadAction<any>) => {
            state.questions.data = action.payload
        },
        // setEditState: (state: any, action: PayloadAction<any>) => {
        //   state.addQuestion.data = action.payload;
        // },
        // editQuestionState: (state: any, { payload }: PayloadAction<any>) => {
        //   state.addQuestion.data
        // },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                getAllQuestions.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.questions.data = payload
                    state.questions.isFetching = false
                }
            )
            .addCase(getAllQuestions.pending, (state: any) => {
                state.questions.isFetching = true
            })
            .addCase(
                getAllQuestions.rejected,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.questions.isError = true
                    state.questions.isFetching = false
                    state.questions.errorMessage = payload
                }
            )
            .addMatcher(
                isAnyOf(createQuestion.pending, updateQuestion.pending),
                (state: any) => {
                    state.addQuestion.isFetching = true
                }
            )
            .addMatcher(
                isAnyOf(createQuestion.fulfilled, updateQuestion.fulfilled),
                (state: any, { payload }: PayloadAction<any>) => {
                    state.addQuestion.isFetching = false
                    state.addQuestion.isSuccess = true
                }
            )

            .addMatcher(
                isAnyOf(createQuestion.rejected, updateQuestion.rejected),
                (state: any, { payload }: PayloadAction<any>) => {
                    state.addQuestion.error = true
                    state.addQuestion.isFetching = false
                    state.addQuestion.errorMessage = payload
                }
            )
    },
})

export const { clearQuestions, setQuestionsData } = questionsSlice.actions
export const questionsSelector = (state: RootState) => state.questions
export default questionsSlice.reducer
