// third-party
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

// project imports
import axios from 'utils/axios'
import { dispatch, RootState } from '../index'
import { openSnackbar } from './snackbar'

// ----------------------------------------------------------------------

export const getAllMantras = createAsyncThunk(
    'mantras/getAllMantras',
    async (args: undefined, { rejectWithValue }) => {
        try {
            const response = await axios.get('admin/mantra/all')
            const { data } = response
            return data
        } catch (e: any) {
            return rejectWithValue(e)
        }
    }
)

export const createMantra = createAsyncThunk(
    'mantras/createMantra',
    async (quote: any, { rejectWithValue }) => {
        try {
            const response = await axios.post(`admin/mantra`, quote)
            const { data } = response
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Mantra created.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success',
                    },
                    close: false,
                })
            )
            dispatch(getAllMantras())
            return data
        } catch (e) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Error.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    severity: 'error',
                    alert: {
                        color: 'error',
                    },
                    close: false,
                })
            )
        }
        return null
    }
)

export const updateMantras = createAsyncThunk(
    'mantras/updateMantras',
    async ({ mantra, uid }: any, { rejectWithValue }) => {
        try {
            const response = await axios.put(`admin/mantra/${uid}`, {
                mantra,
            })
            const { data } = response
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Mantra updated.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success',
                    },
                    close: false,
                })
            )
            dispatch(getAllMantras())
            return data
        } catch (e) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Error.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    severity: 'error',
                    alert: {
                        color: 'error',
                    },
                    close: false,
                })
            )
        }
        return null
    }
)
export const deleteMantra = createAsyncThunk(
    'mantras/deleteMantra',
    async ({ mantra }: any, { rejectWithValue }) => {
        const uid = mantra?.id
        try {
            const response = await axios.delete(`admin/mantra/${uid}`)
            const { data } = response
            dispatch(getAllMantras())
            return data
        } catch (e) {
            return rejectWithValue(e)
        }
    }
)

const apiResponse: any = {
    data: [],
    error: false,
    errorMessage: '',
    isFetching: false,
    isSuccess: false,
    successMessage: '',
}

const initialState: any = {
    mantras: apiResponse,
    addMantra: apiResponse,
    updateMantra: apiResponse,
}

const MantrasSlice = createSlice({
    name: 'mantras',
    initialState,
    reducers: {
        clearMantras: (state: any, action: PayloadAction<any>) => {
            state.mantras = apiResponse
        },
        setMantrasData: (state: any, action: PayloadAction<any>) => {
            state.mantras.data = action.payload
        },
        // setEditState: (state: any, action: PayloadAction<any>) => {
        //   state.addQuestion.data = action.payload;
        // },
        // editQuotestate: (state: any, { payload }: PayloadAction<any>) => {
        //   state.addQuestion.data
        // },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllMantras.pending, (state: any) => {
                state.mantras.isFetching = true
            })
            .addCase(
                getAllMantras.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.mantras.data = payload
                    state.mantras.isFetching = false
                }
            )
            .addCase(
                getAllMantras.rejected,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.mantras.isError = true
                    state.mantras.isFetching = false
                    state.mantras.errorMessage = payload
                }
            )
            .addCase(createMantra.pending, (state: any) => {
                state.addMantra.isFetching = true
            })
            .addCase(
                createMantra.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.addMantra.isFetching = false
                    state.addMantra.isSuccess = true
                }
            )
            .addCase(
                createMantra.rejected,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.addMantra.isError = true
                    state.addMantra.isFetching = false
                    state.addMantra.errorMessage = payload
                }
            )
            .addCase(updateMantras.pending, (state: any) => {
                state.updateMantra.isFetching = true
            })
            .addCase(
                updateMantras.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.updateMantra.isFetching = false
                    state.updateMantra.isSuccess = true
                }
            )
            .addCase(
                updateMantras.rejected,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.updateMantra.isError = true
                    state.updateMantra.isFetching = false
                    state.updateMantra.errorMessage = payload
                }
            )
    },
})

export const { clearMantras, setMantrasData } = MantrasSlice.actions
export const mantrasSelector = (state: RootState) => state.mantras
export default MantrasSlice.reducer
