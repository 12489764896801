import { useLocation, useNavigate } from 'react-router-dom'

// project imports
import useAuth from 'hooks/useAuth'
import { GuardProps } from 'types'
import { useEffect } from 'react'
import { activeItem } from 'store/slices/menu'
import { dispatch } from 'store'

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
    const { isLoggedIn } = useAuth()
    const navigate = useNavigate()
    const location = useLocation()
    useEffect(() => {
        if (location.pathname) {
            dispatch(activeItem([location.pathname.split('/')[1]]))
        }
    }, [location.pathname])

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/login', { replace: true })
        }
    }, [isLoggedIn, navigate])

    return children
}

export default AuthGuard
