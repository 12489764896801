import React, { useMemo } from 'react'
import { Outlet } from 'react-router-dom'

// material-ui
import { styled, useTheme, Theme } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import Toolbar from '@mui/material/Toolbar'
import useMediaQuery from '@mui/material/useMediaQuery'

// project imports
import Header from './Header'
import Sidebar from './Sidebar'
import useConfig from 'hooks/useConfig'
import { drawerWidth } from 'store/constant'
import { openDrawer } from 'store/slices/menu'
import { useDispatch, useSelector } from 'store'

// assets

interface MainStyleProps {
    theme: Theme
    open: boolean
}

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }: MainStyleProps) => ({
        ...theme.typography.mainContent,
        ...(!open && {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.shorter,
            }),
            [theme.breakpoints.up('md')]: {
                marginLeft: -(drawerWidth - 20),
                width: `calc(100% - ${drawerWidth}px)`,
            },
            [theme.breakpoints.down('md')]: {
                marginLeft: '20px',
                width: `calc(100% - ${drawerWidth}px)`,
                padding: '16px',
            },
            [theme.breakpoints.down('sm')]: {
                marginLeft: '10px',
                width: `calc(100% - ${drawerWidth}px)`,
                padding: '16px',
                marginRight: '10px',
            },
        }),
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.shorter,
            }),
            marginLeft: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            width: `calc(100% - ${drawerWidth}px)`,
            [theme.breakpoints.down('md')]: {
                marginLeft: '20px',
            },
            [theme.breakpoints.down('sm')]: {
                marginLeft: '10px',
            },
        }),
    })
)

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme()
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'))

    const dispatch = useDispatch()
    const { drawerOpen } = useSelector((state) => state.menu)
    const { container } = useConfig()

    React.useEffect(() => {
        dispatch(openDrawer(!matchDownMd))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd])

    const header = useMemo(
        () => (
            <Toolbar>
                <Header />
            </Toolbar>
        ),
        []
    )

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: drawerOpen
                        ? theme.transitions.create('width')
                        : 'none',
                }}
            >
                {header}
            </AppBar>

            {/* drawer */}
            <Sidebar />

            {/* main content */}
            <Main theme={theme} open={drawerOpen} sx={{ borderRadius: '10px' }}>
                {/* breadcrumb */}
                {container && (
                    <Container maxWidth="lg">
                        <Outlet />
                    </Container>
                )}
                {!container && (
                    <>
                        {/* <Breadcrumbs
              separator={IconChevronRight}
              navigation={navigation}
              icon
              title
              rightAlign
            /> */}
                        <Outlet />
                    </>
                )}
            </Main>
        </Box>
    )
}

export default MainLayout
