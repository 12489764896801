// third-party
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

// project imports
import axios from 'utils/axios'
import { RootState } from '../index'

// ----------------------------------------------------------------------

export const getClients = createAsyncThunk(
    'getClients',
    async (args: undefined, { rejectWithValue }) => {
        try {
            const response = await axios.get('/admin/clients')
            const { data } = response
            return data
        } catch (e: any) {
            return rejectWithValue(e)
        }
    }
)

export const getClientUsers = createAsyncThunk(
    'getClientUsers',
    async ({ clientId, role }: any, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                role
                    ? `/admin/clients/users/?clientId=${clientId}&role=${role}`
                    : `/admin/clients/users/?clientId=${clientId}`
            )
            const { data } = response
            return data
        } catch (e: any) {
            return rejectWithValue(e)
        }
    }
)

export const generateOtp = createAsyncThunk(
    'generateOtp',
    async ({ clientId, clientAdminId }: any, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `client/otp/?clientId=${clientId}&clientAdminId=${clientAdminId}`
            )
            const { data } = response
            return data
        } catch (e: any) {
            return rejectWithValue(e)
        }
    }
)

const apiResponse: any = {
    data: [],
    error: false,
    errorMessage: '',
    isFetching: false,
    isSuccess: false,
    successMessage: '',
}

const initialState: any = {
    clientsList: apiResponse,
    clientUsersList: apiResponse,
}

const ClientSlice = createSlice({
    name: 'Client',
    initialState,
    reducers: {
        clearQuotes: (state: any, action: PayloadAction<any>) => {
            state.quotes = apiResponse
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getClients.pending, (state: any) => {
                state.clientsList.isFetching = true
            })
            .addCase(
                getClients.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.clientsList.isFetching = false
                    state.clientsList.isSuccess = true
                    state.clientsList.data = payload?.clients
                }
            )
            .addCase(getClients.rejected, (state: any) => {
                state.clientsList.isFetching = false
            })
            .addCase(getClientUsers.pending, (state: any) => {
                state.clientUsersList.isFetching = true
            })
            .addCase(
                getClientUsers.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.clientUsersList.isFetching = false
                    state.clientUsersList.isSuccess = true
                    state.clientUsersList.data = payload?.users
                }
            )
            .addCase(getClientUsers.rejected, (state: any) => {
                state.clientUsersList.isFetching = false
            })
    },
})

export const { clearQuotes } = ClientSlice.actions
export const clientsSelector = (state: RootState) => state.clients
export default ClientSlice.reducer
