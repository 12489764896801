// third-party
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

// project imports
import axios from 'utils/axios'
import { dispatch, RootState } from '../index'
import { openSnackbar } from './snackbar'

// ----------------------------------------------------------------------

export const getAppConfig = createAsyncThunk(
    'loans/app-config',
    async (args: undefined, { rejectWithValue }) => {
        try {
            const response = await axios.get('loans/app-config')
            const { data } = response
            return data
        } catch (e: any) {
            return rejectWithValue(e)
        }
    }
)

export const getDigifiStatistic = createAsyncThunk(
    'loans/status-group',
    async (args: undefined, { rejectWithValue }) => {
        try {
            const response = await axios.get('loans/applications/status-group')
            const { data } = response
            return data
        } catch (e: any) {
            return rejectWithValue(e)
        }
    }
)
export const updateConfig = createAsyncThunk(
    'loans/update-config',
    async (obj: any, { rejectWithValue }) => {
        try {
            const response = await axios.put(`loans/app-config`, {
                ...obj,
            })
            const { data } = response
            dispatch(
                openSnackbar({
                    open: true,
                    message: data?.msg || 'App config updated successfully.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success',
                    },
                    close: false,
                })
            )

            return data
        } catch (e) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Error.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    severity: 'error',
                    alert: {
                        color: 'error',
                    },
                    close: false,
                })
            )
        }
        return null
    }
)

const apiResponse: any = {
    data: [],
    error: false,
    errorMessage: '',
    isFetching: false,
    isSuccess: false,
    successMessage: '',
}

const initialState: any = {
    digifiConfig: apiResponse,
    updateDigifiConfig: apiResponse,
    digifiStatistic: apiResponse,
}

const ThrottleSlice = createSlice({
    name: 'throttle',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAppConfig.pending, (state: any) => {
                state.digifiConfig.isFetching = true
            })
            .addCase(
                getAppConfig.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.digifiConfig.data = payload
                    state.digifiConfig.isFetching = false
                }
            )
            .addCase(
                getAppConfig.rejected,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.digifiConfig.isError = true
                    state.digifiConfig.isFetching = false
                    state.digifiConfig.errorMessage = payload
                }
            )
            .addCase(getDigifiStatistic.pending, (state: any) => {
                state.digifiStatistic.isFetching = true
            })
            .addCase(
                getDigifiStatistic.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.digifiStatistic.isFetching = false
                    state.digifiStatistic.data = payload
                }
            )
            .addCase(
                getDigifiStatistic.rejected,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.digifiStatistic.isFetching = false
                }
            )
    },
})

export const throttleSelector = (state: RootState) => state.throttle
export default ThrottleSlice.reducer
