// third-party
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

// project imports
import axios from 'utils/axios'
import { dispatch, RootState } from '../index'
import { openSnackbar } from './snackbar'

// ----------------------------------------------------------------------

export const getExitSurveyQuestion = createAsyncThunk(
    'exitSurvey/getExitSurveyQuestion',
    async (args: undefined, { rejectWithValue }) => {
        try {
            const response = await axios.get('admin/exit/survey')
            const { data } = response
            return data
        } catch (e: any) {
            return rejectWithValue(e)
        }
    }
)

export const createExitSurveyQuestion = createAsyncThunk(
    'questions/createExitSurveyQuestion',
    async ({ question }: any, { rejectWithValue }) => {
        try {
            const response = await axios.post('admin/exit/survey', question)
            const { data } = response
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Exit Question created.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success',
                    },
                    close: false,
                })
            )
            dispatch(getExitSurveyQuestion())
            return data
        } catch (e) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Error.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    severity: 'error',
                    alert: {
                        color: 'error',
                    },
                    close: false,
                })
            )
        }
        return null
    }
)
// createExitSurveyQuestion
export const updateExitSurveyQuestion = createAsyncThunk(
    'questions/updateExitSurveyQuestion',
    async ({ question }: any, { rejectWithValue }) => {
        const { question: newQuestion, options, uid: questionID } = question
        try {
            const response = await axios.put(`admin/exit/survey`, {
                data: {
                    question: newQuestion,
                    options,
                },
                questionID,
            })
            const { data } = response
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Question updated.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success',
                    },
                    close: false,
                })
            )
            dispatch(getExitSurveyQuestion())
            return data
        } catch (e) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Error.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    severity: 'error',
                    alert: {
                        color: 'error',
                    },
                    close: false,
                })
            )
        }
        return null
    }
)

const apiResponse: any = {
    data: {},
    error: false,
    errorMessage: '',
    isFetching: false,
    isSuccess: false,
    successMessage: '',
}

const questionState: any = {
    question: '',
    options: [],
}

const initialState: any = {
    exitQuestion: apiResponse,
    addExitQuestion: {
        ...apiResponse,
        data: questionState,
    },
}

const exitSurveyQuestion = createSlice({
    name: 'exitSurveyQuestion',
    initialState,
    reducers: {
        clearExitSurvey: (state: any, action: PayloadAction<any>) => {
            state.exitQuestion = apiResponse
        },
        editMode: (state: any, action: PayloadAction<any>) => {
            state.exitQuestion.isSuccess = false
        },
        setQuestionsData: (state: any, action: PayloadAction<any>) => {
            state.questions.data = action.payload
        },
        // setEditState: (state: any, action: PayloadAction<any>) => {
        //   state.addExitQuestion.data = action.payload;
        // },
        // editQuestionState: (state: any, { payload }: PayloadAction<any>) => {
        //   state.addExitQuestion.data
        // },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                getExitSurveyQuestion.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.exitQuestion.data = payload
                    state.exitQuestion.isFetching = false
                    state.exitQuestion.isSuccess = true
                }
            )
            .addCase(getExitSurveyQuestion.pending, (state: any) => {
                state.exitQuestion.isFetching = true
            })
            .addCase(
                getExitSurveyQuestion.rejected,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.exitQuestion.isError = true
                    state.exitQuestion.isFetching = false
                    state.exitQuestion.errorMessage = payload
                }
            )
            .addCase(createExitSurveyQuestion.pending, (state: any) => {
                state.addExitQuestion.isFetching = true
            })
            .addCase(
                createExitSurveyQuestion.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.addExitQuestion.isFetching = false
                    state.addExitQuestion.isSuccess = true
                }
            )

            .addCase(
                createExitSurveyQuestion.rejected,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.addExitQuestion.error = true
                    state.addExitQuestion.isFetching = false
                    state.addExitQuestion.errorMessage = payload
                }
            )
    },
})

export const { clearExitSurvey, setQuestionsData, editMode } =
    exitSurveyQuestion.actions
export const exitQuestionSelector = (state: RootState) => state.exitSurvey
export default exitSurveyQuestion.reducer
