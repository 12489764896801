import React, { useState } from 'react'

import useAuth from 'hooks/useAuth'
import ForgotPassword from './ForgotPassword3'
import CodeVerification from './CodeVerification'
import ResetPassword from '../authentication3/ResetPassword3'

const ForgotPasswordFlow = () => {
    const { currentSection } = useAuth()

    const [state, setState] = useState({
        email: '',
        code: '',
        password: '',
    })

    const renderCurrentSelection = () => {
        switch (currentSection) {
            case '':
                return <ForgotPassword state={state} setState={setState} />
            case 'codeVerify':
                return <CodeVerification state={state} setState={setState} />
            case 'reset':
                return <ResetPassword state={state} setState={setState} />

            default:
                // Add a default case to explicitly handle unexpected values
                return null
        }
    }
    return <>{renderCurrentSelection()}</>
}

export default ForgotPasswordFlow
