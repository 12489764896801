import { Link } from 'react-router-dom'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
    Button,
    Divider,
    Grid,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material'

// project imports

import AuthCardWrapper from '../AuthCardWrapper'
import Logo from 'ui-component/Logo'
import AnimateButton from 'ui-component/extended/AnimateButton'

import AuthFooter from 'ui-component/cards/AuthFooter'

import useAuth from 'hooks/useAuth'
import { useDispatch } from 'store'
import { openSnackbar } from 'store/slices/snackbar'
import { useState } from 'react'
import AuthWrapper1 from '../AuthWrapper1'
import AuthCodeVerification from './AuthCodeVerification'

// assets

// ===========================|| AUTH3 - CODE VERIFICATION ||=========================== //

const CodeVerification = ({ state, setState }: any) => {
    const theme = useTheme()
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'))
    const [disabled, setDisabled] = useState(false)
    const dispatch = useDispatch()
    const { forgotPassword } = useAuth()

    const handleForgotPassword = async () => {
        setDisabled(true)

        const response = await forgotPassword(state.email)
        setTimeout(() => setDisabled(false), 5000)

        if (response.status === 200) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response?.data?.msg,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success',
                    },
                    close: false,
                })
            )
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: response?.ex,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    severity: 'error',
                    alert: {
                        color: 'error',
                    },
                    close: false,
                })
            )
        }
    }

    return (
        <AuthWrapper1>
            <Grid
                container
                direction="column"
                justifyContent="flex-end"
                sx={{ minHeight: '100vh' }}
            >
                <Grid item xs={12}>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{ minHeight: 'calc(100vh - 68px)' }}
                    >
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <AuthCardWrapper>
                                <Grid
                                    container
                                    spacing={2}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item sx={{ mb: 3 }}>
                                        <Link to="/login">
                                            <Logo />
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            direction={
                                                matchDownSM
                                                    ? 'column-reverse'
                                                    : 'row'
                                            }
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item>
                                                <Stack
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    spacing={1}
                                                >
                                                    <Typography
                                                        color={
                                                            theme.palette
                                                                .secondary.main
                                                        }
                                                        gutterBottom
                                                        variant={
                                                            matchDownSM
                                                                ? 'h3'
                                                                : 'h2'
                                                        }
                                                    >
                                                        Enter Verification Code
                                                    </Typography>
                                                    <Typography
                                                        variant="subtitle1"
                                                        fontSize="1rem"
                                                        sx={{ color: '#000' }}
                                                    >
                                                        We send you on mail.
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        fontSize="0.875rem"
                                                        color="#000"
                                                        textAlign={
                                                            matchDownSM
                                                                ? 'center'
                                                                : 'inherit'
                                                        }
                                                    >
                                                        We’ve send you code on
                                                        your email.
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AuthCodeVerification
                                            state={state}
                                            setState={setState}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid
                                            item
                                            container
                                            direction="column"
                                            alignItems="center"
                                            xs={12}
                                        >
                                            <Typography
                                                component={Link}
                                                to="#"
                                                variant="subtitle1"
                                                sx={{
                                                    textDecoration: 'none',
                                                    color: '#000',
                                                }}
                                                textAlign={
                                                    matchDownSM
                                                        ? 'center'
                                                        : 'inherit'
                                                }
                                            >
                                                Did not receive the email? Check
                                                your spam filter, or
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AnimateButton>
                                            <Button
                                                disableElevation
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="outlined"
                                                color="secondary"
                                                disabled={disabled}
                                                onClick={handleForgotPassword}
                                            >
                                                Resend Code
                                            </Button>
                                        </AnimateButton>
                                    </Grid>
                                </Grid>
                            </AuthCardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
                    <AuthFooter />
                </Grid>
            </Grid>
        </AuthWrapper1>
    )
}

export default CodeVerification
