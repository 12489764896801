// third-party
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'

// project imports
import axios from 'utils/axios'
import { dispatch, RootState } from '../index'
import { openSnackbar } from './snackbar'

// types

// ----------------------------------------------------------------------//

export const getAllUsers = createAsyncThunk(
    'users/getAllUsers',
    async (
        {
            offset = 0,
            limit = 30,
            search = '',
            sortKey = '',
            sortOrder = '',
            role = 'USER',
        }: any,
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.get(
                `admin/users?role=${role}&sortKey=${sortKey}&sortOrder=${sortOrder}`,
                {
                    params: {
                        offset,
                        limit,
                        search,
                    },
                }
            )
            const { data } = response
            return data
        } catch (e: any) {
            return rejectWithValue(e)
        }
    }
)

export const getAllUsersNewSort = createAsyncThunk(
    'users/getAllUsersWithSort',
    async (
        {
            offset = 0,
            limit = 30,
            search = '',
            sortKey = 'firstName',
            sortOrder = 'asc',
            role = 'USER',
        }: any,
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.get(
                `admin/users?role=${role}&sortKey=${sortKey}&sortOrder=${sortOrder}`,
                {
                    params: {
                        offset,
                        limit,
                        search,
                    },
                }
            )
            const { data } = response
            return data
        } catch (e: any) {
            return rejectWithValue(e)
        }
    }
)

export const downloadUserCSV = createAsyncThunk(
    'users/downloadUserCSV',
    async (args: undefined, { rejectWithValue }) => {
        try {
            const { data } = await axios.get('admin/users?action=DOWNLOAD')
            return data
        } catch (e: any) {
            return rejectWithValue(e)
        }
    }
)
export const getDemoUsers = createAsyncThunk(
    'users/getDemoUsers',
    async (args: undefined, { rejectWithValue }) => {
        try {
            const response = await axios.get('admin/users?role=DEMO')
            const { data } = response
            return data
        } catch (e: any) {
            return rejectWithValue(e)
        }
    }
)
export const createDemoUser = createAsyncThunk(
    'quotes/createDemoUser',
    async ({ user }: any, { rejectWithValue }) => {
        try {
            const response = await axios.post(`admin/demo/user`, user)
            const { data } = response
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Demo user  created.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success',
                    },
                    close: false,
                })
            )
            dispatch(getDemoUsers())
            return data
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e?.msg?.message || e?.err,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    severity: 'error',
                    alert: {
                        color: 'error',
                    },
                    close: false,
                })
            )
            return rejectWithValue(e)
        }
    }
)
export const resetDemoUser = createAsyncThunk(
    'users/resetDemoUser',
    async ({ userID }: any, { rejectWithValue }) => {
        try {
            const response = await axios.put(`admin/demo/user/${userID}`)
            const { data } = response
            dispatch(getDemoUsers())
            return data
        } catch (e: any) {
            return rejectWithValue(e)
        }
    }
)
export const deleteDemoUser = createAsyncThunk(
    'quotes/deleteDemoUser',
    async ({ userID }: any, { rejectWithValue }) => {
        try {
            const response = await axios.delete(`admin/demo/user/${userID}`)
            const { data } = response
            dispatch(getDemoUsers())
            return data
        } catch (e) {
            return rejectWithValue(e)
        }
    }
)
export const getAllDeleteUsers = createAsyncThunk(
    'users/getAllDeleteUsers',
    async (args: undefined, { rejectWithValue }) => {
        try {
            const response = await axios.get('admin/deleted-users')
            const { data } = response
            return data
        } catch (e: any) {
            return rejectWithValue(e)
        }
    }
)
export const getAllAccounts = createAsyncThunk(
    'users/getAllAccounts',
    async (userID: any, { rejectWithValue }) => {
        try {
            const response = await axios.get(`admin/yodlee/${userID}`)
            const { data } = response
            return data
        } catch (e: any) {
            return rejectWithValue(e)
        }
    }
)
export const resetEmail = createAsyncThunk(
    'users/resetEmail',
    async (userID: any, { rejectWithValue }) => {
        try {
            const response = await axios.post(`admin/reset-email/${userID}`)
            const { data } = response
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Reset Email Successfully.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success',
                    },
                    close: false,
                })
            )
            return data
        } catch (e: any) {
            return rejectWithValue(e)
        }
    }
)
export const getMindQuizDetails = createAsyncThunk(
    'users/getMindQuizDetails',
    async (userID: string, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/admin/mindquiz/${userID}`)
            const { data } = response
            return data
        } catch (e: any) {
            return rejectWithValue(e)
        }
    }
)

export const getSpinwheelList = createAsyncThunk(
    'users/getSpinwheelList',
    async (userID: any, { rejectWithValue }) => {
        try {
            const response = await axios.get(`admin/spinwheel/${userID}`)
            const { data } = response
            return data
        } catch (e: any) {
            return rejectWithValue(e)
        }
    }
)

export const getGoalList = createAsyncThunk(
    'users/getGoalList',
    async (userID: any, { rejectWithValue }) => {
        try {
            const response = await axios.get(`admin/goals/${userID}`)
            const { data } = response
            return data
        } catch (e: any) {
            return rejectWithValue(e)
        }
    }
)
export const getJourney = createAsyncThunk(
    'users/getJourneyList',
    async (userID: any, { rejectWithValue }) => {
        try {
            const response = await axios.get(`admin/journey/${userID}`)
            const { data } = response
            return data
        } catch (e: any) {
            return rejectWithValue(e)
        }
    }
)

export const getWellnessFeedback = createAsyncThunk(
    'users/getWellnessFeedback',
    async (userID: any, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `admin/wellness-feedback/${userID}`
            )
            const { data } = response
            return data
        } catch (e: any) {
            return rejectWithValue(e)
        }
    }
)
export const getBudgetList = createAsyncThunk(
    'users/getBudgetList',
    async (userID: any, { rejectWithValue }) => {
        try {
            const response = await axios.get(`admin/yodlee/spending/${userID}`)
            const { data } = response
            return data
        } catch (e: any) {
            return rejectWithValue(e)
        }
    }
)

const apiResponse: any = {
    data: [],
    error: false,
    errorMessage: '',
    isFetching: false,
    isSuccess: false,
    successMessage: '',
}

const initialState: any = {
    users: apiResponse,
    userCSV: apiResponse,
    accountList: apiResponse,
    emailReset: apiResponse,
    deletedUsers: apiResponse,
    demoUsers: apiResponse,
    mindQuizDetails: apiResponse,
    spinwheelList: apiResponse,
    goalList: apiResponse,
    journeyList: apiResponse,
    wellnessFeedBackList: apiResponse,
    budgetingList: apiResponse,
    addDemoUser: { ...apiResponse },
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        clearUser: (state: any) => {
            state.users = apiResponse
        },
        clearAccountList: (state: any) => {
            state.accountList = apiResponse
        },
        removeLastUsers: (state: any, action: PayloadAction<any>) => {
            const { index } = action.payload
            const temp = state?.users?.data?.slice(0, index)
            state.users.data = temp
        },
        setCoachingStatus: (state: any, action: PayloadAction<any>) => {
            const { uid, coachingStatus } = action.payload
            console.log('payload:', action.payload)
            state.users.data.map((item: any) => {
                if (item.uid === uid) {
                    console.log('found record', item)
                    item.coachingStatus = coachingStatus
                }
                return item
            })
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllUsers.pending, (state: any) => {
                state.users.isFetching = true
            })
            .addCase(
                getAllUsers.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.users.data = [...state.users.data, ...payload]
                    state.users.isFetching = false
                }
            )

            .addCase(
                getAllUsers.rejected,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.users.isError = true
                    state.users.isFetching = false
                    state.users.errorMessage = payload
                }
            )
            .addCase(getAllUsersNewSort.pending, (state: any) => {
                state.users.isFetching = true
            })
            .addCase(
                getAllUsersNewSort.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.users.data = [...payload]
                    state.users.isFetching = false
                }
            )

            .addCase(
                getAllUsersNewSort.rejected,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.users.isError = true
                    state.users.isFetching = false
                    state.users.errorMessage = payload
                }
            )
            .addCase(downloadUserCSV.pending, (state: any) => {
                state.userCSV.isFetching = true
            })
            .addCase(
                downloadUserCSV.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.userCSV.data = payload
                    state.userCSV.isFetching = false
                }
            )

            .addCase(
                downloadUserCSV.rejected,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.userCSV.isError = true
                    state.userCSV.isFetching = false
                    state.userCSV.errorMessage = payload
                }
            )
            .addCase(getAllDeleteUsers.pending, (state: any) => {
                state.deletedUsers.isFetching = true
            })
            .addCase(
                getAllDeleteUsers.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.deletedUsers.data = payload
                    state.deletedUsers.isFetching = false
                }
            )

            .addCase(
                getAllDeleteUsers.rejected,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.deletedUsers.isError = true
                    state.deletedUsers.isFetching = false
                    state.deletedUsers.errorMessage = payload
                }
            )
            .addCase(getAllAccounts.pending, (state: any) => {
                state.accountList.isFetching = true
            })
            .addCase(
                getAllAccounts.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.accountList.data = payload
                    state.accountList.isFetching = false
                }
            )
            .addCase(
                getAllAccounts.rejected,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.accountList.isError = true
                    state.accountList.isFetching = false
                    state.accountList.errorMessage = payload
                    state.accountList.data = []
                }
            )
            .addCase(resetEmail.pending, (state: any) => {
                state.emailReset.isFetching = true
            })
            .addCase(
                resetEmail.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.emailReset.isFetching = false
                }
            )
            .addCase(
                resetEmail.rejected,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.emailReset.isFetching = false
                }
            )
            .addCase(getDemoUsers.pending, (state: any) => {
                state.demoUsers.isFetching = true
            })
            .addCase(
                getDemoUsers.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.demoUsers.isFetching = false
                    state.demoUsers.data = payload
                }
            )
            .addCase(
                getDemoUsers.rejected,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.demoUsers.isFetching = false
                }
            )
            .addCase(
                createDemoUser.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.addDemoUser.isFetching = false
                    state.addDemoUser.isSuccess = true
                }
            )
            .addCase(createDemoUser.pending, (state: any) => {
                state.addDemoUser.isFetching = true
            })
            .addCase(
                createDemoUser.rejected,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.addDemoUser.isError = true
                    state.addDemoUser.isFetching = false
                    state.addDemoUser.errorMessage = payload
                }
            )
            .addCase(getMindQuizDetails.pending, (state: any) => {
                state.mindQuizDetails.isFetching = true
            })
            .addCase(
                getMindQuizDetails.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.mindQuizDetails.isFetching = false
                    state.mindQuizDetails.data = payload
                }
            )
            .addCase(
                getMindQuizDetails.rejected,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.mindQuizDetails.isFetching = false
                    state.mindQuizDetails.data = []
                }
            )
            .addCase(getSpinwheelList.pending, (state: any) => {
                state.spinwheelList.isFetching = true
            })
            .addCase(
                getSpinwheelList.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.spinwheelList.isFetching = false
                    state.spinwheelList.data = payload
                }
            )
            .addCase(
                getSpinwheelList.rejected,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.spinwheelList.isFetching = false
                }
            )
            .addCase(getGoalList.pending, (state: any) => {
                state.goalList.isFetching = true
            })
            .addCase(
                getGoalList.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.goalList.isFetching = false
                    state.goalList.data = payload
                }
            )
            .addCase(
                getGoalList.rejected,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.goalList.isFetching = false
                }
            )
            .addCase(getJourney.pending, (state: any) => {
                state.journeyList.isFetching = true
            })
            .addCase(
                getJourney.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.journeyList.isFetching = false
                    state.journeyList.data = payload
                }
            )
            .addCase(
                getJourney.rejected,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.journeyList.isFetching = false
                }
            )
            .addCase(getWellnessFeedback.pending, (state: any) => {
                state.wellnessFeedBackList.isFetching = true
            })
            .addCase(
                getWellnessFeedback.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.wellnessFeedBackList.isFetching = false
                    state.wellnessFeedBackList.data = payload
                }
            )
            .addCase(
                getWellnessFeedback.rejected,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.wellnessFeedBackList.isFetching = false
                }
            )
            .addCase(getBudgetList.pending, (state: any) => {
                state.budgetingList.isFetching = true
            })
            .addCase(
                getBudgetList.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.budgetingList.isFetching = false
                    state.budgetingList.data = payload
                }
            )
            .addCase(
                getBudgetList.rejected,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.budgetingList.isFetching = false
                }
            )
    },
})

// Reducer
export const {
    clearUser,
    clearAccountList,
    removeLastUsers,
    setCoachingStatus,
} = userSlice.actions

export const userSelector = (state: RootState) => state.users

export default userSlice.reducer
