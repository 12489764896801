// material-ui
import { useTheme } from '@mui/material/styles'
import { Button, Grid } from '@mui/material'

import useAuth from 'hooks/useAuth'

// third-party
import OtpInput from 'react18-input-otp'

// ============================|| STATIC - CODE VERIFICATION ||============================ //

const AuthCodeVerification = ({ state, setState }: any) => {
    const theme = useTheme()
    // after i will remove the local state

    const borderColor =
        theme.palette.mode === 'dark'
            ? theme.palette.grey[200]
            : theme.palette.grey[300]

    const { verifyCode } = useAuth()
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <OtpInput
                    value={state.code}
                    onChange={(otpNumber: string) =>
                        setState({ ...state, code: otpNumber })
                    }
                    numInputs={6}
                    containerStyle={{ justifyContent: 'space-between' }}
                    inputStyle={{
                        width: '100%',
                        margin: '8px',
                        padding: '10px',
                        border: `1px solid ${borderColor}`,
                        borderRadius: 4,
                        ':hover': {
                            borderColor: theme.palette.primary.main,
                        },
                    }}
                    focusStyle={{
                        outline: 'none',
                        border: `2px solid ${theme.palette.primary.main}`,
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    disableElevation
                    fullWidth
                    size="large"
                    variant="contained"
                    disabled={state.code?.length < 6}
                    onClick={() => {
                        verifyCode()
                    }}
                >
                    Continue
                </Button>
            </Grid>
            {/* <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between" alignItems="baseline">
                    <Typography color="#000">Did not receive the email? Check your spam filter, or</Typography>
                    <Typography variant="body1" sx={{ minWidth: 85, ml: 2, textDecoration: 'none', cursor: 'pointer' }} color="primary">
                        Resend code
                    </Typography>
                </Stack>
            </Grid> */}
        </Grid>
    )
}
export default AuthCodeVerification
