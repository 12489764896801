// third-party
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

// project imports
import axios from 'utils/axios'
import { RootState } from '../index'

// ----------------------------------------------------------------------

export const getMessageHistory = createAsyncThunk(
    'getMessageHistory',
    async (
        {
            sort = '',
            offset = 0,
            limit = 10,
            userId = '',
            search,
            override,
        }: any,
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.get('/admin/message-history', {
                params: {
                    sort,
                    offset,
                    limit,
                    userId,
                    search,
                },
            })
            const { data } = response
            return { data, override }
        } catch (e: any) {
            return rejectWithValue(e)
        }
    }
)

export const getMessageDetails = createAsyncThunk(
    'getMessageDetails',
    async (id: any, { rejectWithValue }) => {
        try {
            const response = await axios.get(`/admin/message/${id}`)
            const { data } = response
            return data
        } catch (e: any) {
            return rejectWithValue(e)
        }
    }
)

const apiResponse: any = {
    data: [],
    error: false,
    errorMessage: '',
    isFetching: false,
    isSuccess: false,
    successMessage: '',
}

const initialState: any = {
    messageList: apiResponse,
    messageDetails: { ...apiResponse, data: {} },
}

const CommunicationLogSlice = createSlice({
    name: 'CommunicationLog',
    initialState,
    reducers: {
        resetMessageList: (state: any) => {
            state.messageList = apiResponse
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMessageHistory.pending, (state: any) => {
                state.messageList.isFetching = true
            })
            .addCase(
                getMessageHistory.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    const { override, data } = payload
                    state.messageList.isFetching = false
                    state.messageList.isSuccess = true

                    if (override) {
                        state.messageList.data = [...data]
                    } else {
                        state.messageList.data = [
                            ...state.messageList.data,
                            ...data,
                        ]
                    }
                }
            )
            .addCase(getMessageHistory.rejected, (state: any) => {
                state.messageList.isFetching = false
            })
            .addCase(getMessageDetails.pending, (state: any) => {
                state.messageDetails.isFetching = true
            })
            .addCase(
                getMessageDetails.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.messageDetails.isFetching = false
                    state.messageDetails.isSuccess = true
                    state.messageDetails.data = payload
                }
            )
            .addCase(getMessageDetails.rejected, (state: any) => {
                state.messageDetails.isFetching = false
            })
    },
})

export const { resetMessageList } = CommunicationLogSlice.actions
export const CommunicationLogSelector = (state: RootState) =>
    state.communicationLog
export default CommunicationLogSlice.reducer
