// third-party
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

// project imports
import axios from 'utils/axios'
import { dispatch, RootState } from '../index'
import { openSnackbar } from './snackbar'

// ----------------------------------------------------------------------

export const getAllQuotes = createAsyncThunk(
    'quotes/getAllQuotes',
    async (args: undefined, { rejectWithValue }) => {
        try {
            const response = await axios.get('admin/quote')
            const { data } = response
            return data
        } catch (e: any) {
            return rejectWithValue(e)
        }
    }
)

export const createQuotes = createAsyncThunk(
    'quotes/createQuotes',
    async (quote: any, { rejectWithValue }) => {
        try {
            const response = await axios.post(`admin/quote`, quote)
            const { data } = response
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Quote created.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success',
                    },
                    close: false,
                })
            )
            dispatch(getAllQuotes())
            return data
        } catch (e) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Error.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    severity: 'error',
                    alert: {
                        color: 'error',
                    },
                    close: false,
                })
            )
        }
        return null
    }
)

export const updateQuotes = createAsyncThunk(
    'quotes/updateQuotes',
    async ({ quote, uid }: any, { rejectWithValue }) => {
        try {
            const response = await axios.put(`admin/quote`, {
                quoteID: uid,
                data: {
                    quote,
                },
            })
            const { data } = response
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Quote updated.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success',
                    },
                    close: false,
                })
            )
            dispatch(getAllQuotes())
            return data
        } catch (e) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Error.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    severity: 'error',
                    alert: {
                        color: 'error',
                    },
                    close: false,
                })
            )
        }
        return null
    }
)
export const deleteQuotes = createAsyncThunk(
    'quotes/deleteQuestion',
    async ({ quote }: any, { rejectWithValue }) => {
        const uid = quote?.uid
        try {
            const response = await axios.delete(`admin/quote/${uid}`)
            const { data } = response
            dispatch(getAllQuotes())
            return data
        } catch (e) {
            return rejectWithValue(e)
        }
    }
)

const apiResponse: any = {
    data: [],
    error: false,
    errorMessage: '',
    isFetching: false,
    isSuccess: false,
    successMessage: '',
}

const initialState: any = {
    quotes: apiResponse,
    addQuotes: apiResponse,
    updateQuote: apiResponse,
}

const QuotesSlice = createSlice({
    name: 'quotes',
    initialState,
    reducers: {
        clearQuotes: (state: any, action: PayloadAction<any>) => {
            state.quotes = apiResponse
        },
        setQuotesData: (state: any, action: PayloadAction<any>) => {
            state.quotes.data = action.payload
        },
        // setEditState: (state: any, action: PayloadAction<any>) => {
        //   state.addQuestion.data = action.payload;
        // },
        // editQuotestate: (state: any, { payload }: PayloadAction<any>) => {
        //   state.addQuestion.data
        // },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllQuotes.pending, (state: any) => {
                state.quotes.isFetching = true
            })
            .addCase(
                getAllQuotes.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.quotes.data = payload
                    state.quotes.isFetching = false
                }
            )
            .addCase(
                getAllQuotes.rejected,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.quotes.isError = true
                    state.quotes.isFetching = false
                    state.quotes.errorMessage = payload
                }
            )
            .addCase(createQuotes.pending, (state: any) => {
                state.addQuotes.isFetching = true
            })
            .addCase(
                createQuotes.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.addQuotes.isFetching = false
                    state.addQuotes.isSuccess = true
                }
            )
            .addCase(
                createQuotes.rejected,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.addQuotes.isError = true
                    state.addQuotes.isFetching = false
                    state.addQuotes.errorMessage = payload
                }
            )
            .addCase(updateQuotes.pending, (state: any) => {
                state.updateQuote.isFetching = true
            })
            .addCase(
                updateQuotes.fulfilled,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.updateQuote.isFetching = false
                    state.updateQuote.isSuccess = true
                }
            )
            .addCase(
                updateQuotes.rejected,
                (state: any, { payload }: PayloadAction<any>) => {
                    state.updateQuote.isError = true
                    state.updateQuote.isFetching = false
                    state.updateQuote.errorMessage = payload
                }
            )
    },
})

export const { clearQuotes, setQuotesData } = QuotesSlice.actions
export const quotesSelector = (state: RootState) => state.quotes
export default QuotesSlice.reducer
