// material-ui
import { useTheme } from '@mui/material/styles'
import {
    Box,
    Button,
    FormHelperText,
    InputLabel,
    OutlinedInput,
} from '@mui/material'
import { useDispatch } from 'store'

// third party
import * as Yup from 'yup'
import { Formik } from 'formik'

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton'
import useAuth from 'hooks/useAuth'
import useScriptRef from 'hooks/useScriptRef'
import { openSnackbar } from 'store/slices/snackbar'
import { useState } from 'react'

// ========================|| FIREBASE - FORGOT PASSWORD ||======================== //

const AuthForgotPassword = ({ ...others }) => {
    const theme = useTheme()
    const scriptedRef = useScriptRef()
    const dispatch = useDispatch()
    const [isFetching, setIsFetching] = useState(false)

    const { forgotPassword } = useAuth()

    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
                submit: null,
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string()
                    .email('Must be a valid email')
                    .max(255)
                    .required('Email is required'),
            })}
            onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
            ) => {
                try {
                    setIsFetching(true)
                    others.setState({ ...others.state, email: values.email })
                    const response = await forgotPassword(values.email)

                    if (response.status === 200) {
                        setIsFetching(false)
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: response?.data?.msg,
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right',
                                },
                                variant: 'alert',
                                alert: {
                                    color: 'success',
                                },
                                close: false,
                            })
                        )
                    } else {
                        setIsFetching(false)
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: response?.ex,
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right',
                                },
                                variant: 'alert',
                                severity: 'error',
                                alert: {
                                    color: 'error',
                                },
                                close: false,
                            })
                        )
                    }

                    if (scriptedRef.current) {
                        setStatus({ success: true })
                        setSubmitting(false)
                    }
                } catch (err: any) {
                    setIsFetching(false)
                    if (scriptedRef.current) {
                        setStatus({ success: false })

                        setSubmitting(false)
                    }
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
            }) => (
                <form noValidate onSubmit={handleSubmit} {...others}>
                    <InputLabel htmlFor="outlined-adornment-email-login">
                        Email Address / Username
                    </InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-email-login"
                        type="email"
                        value={values.email}
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        fullWidth
                        label="Email Address / Username"
                        error={Boolean(touched.email && errors.email)}
                        sx={{ ...theme.typography.customInput }}
                    />
                    {touched.email && errors.email && (
                        <FormHelperText
                            error
                            id="standard-weight-helper-text-email-forgot"
                        >
                            {errors.email}
                        </FormHelperText>
                    )}

                    {errors.submit && (
                        <Box sx={{ mt: 3 }}>
                            <FormHelperText error>
                                {errors.submit}
                            </FormHelperText>
                        </Box>
                    )}

                    <Box sx={{ mt: 2 }}>
                        <AnimateButton>
                            <Button
                                disableElevation
                                disabled={
                                    errors.email === 'Must be a valid email' ||
                                    values.email.trim() === '' ||
                                    isFetching
                                }
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                sx={{ color: '#fff' }}
                            >
                                Send Mail
                            </Button>
                        </AnimateButton>
                    </Box>
                </form>
            )}
        </Formik>
    )
}

export default AuthForgotPassword
