import other from './other'
import { NavItemType } from 'types'

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [other],
}

export default menuItems
