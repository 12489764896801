import { lazy } from 'react'

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard'
import MinimalLayout from 'layout/MinimalLayout'
import NavMotion from 'layout/NavMotion'
import Loadable from 'ui-component/Loadable'
import ForgotPasswordFlow from 'views/pages/authentication/authentication/ForgotPasswordFlow'

// login routing
const AuthLogin = Loadable(
    lazy(() => import('views/pages/authentication/authentication/Login3'))
)

const AuthResetPassword3 = Loadable(
    lazy(
        () =>
            import('views/pages/authentication/authentication3/ResetPassword3')
    )
)

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/login',
            element: <AuthLogin />,
        },
        {
            path: '/reset-password',
            element: <AuthResetPassword3 />,
        },
        {
            path: '/forgot-password',
            element: <ForgotPasswordFlow />,
        },
        {
            path: '*',
            element: <AuthLogin />,
        },
    ],
}

export default LoginRoutes
